<style>
@media (min-width: 600px) {
    .contact-left {
        float: left;
    }
}
ul {
  list-style-type: none;
  padding: 0;
}
</style>

<div class="contact-left">
    <h2>Contact</h2>
    <ul>
        <li>
            <a href="https://www.hackerrank.com/mannattuli6">Hackerrank</a>
        </li>
        <li>
            <a href="https://filmzmania.herokuapp.com/profile/1">Filmania</a>
        </li>
        <li>
            <a href="https://github.com/mannattuli">Github</a>
        </li>
        <li>
            <a href="https://www.linkedin.com/in/mannattuli/">Linkedin</a>
        </li>
        <li>
            <a href="https://www.goodreads.com/user/show/137677761-mxnnxt">Goodreads</a>
        </li>
    </ul>
</div>
