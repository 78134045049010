<script>
	import { Router, Link, Route } from "svelte-routing";

	import Home from "./pages/Index.svelte";
	import Blog from "./pages/Blog.svelte";
	import Song from "./pages/Songs.svelte"

	import BasicAi from "./pages/blog/BasicAi.svelte";
	import EcomAi from "./pages/blog/EcomAi.svelte";
  
	export let url = "";
</script>
<style>
</style>
<Router url="{url}">
	<div>
	  <Route path="/"><Home /></Route>
	  <Route path="/blog"><Blog /></Route>
<!-- 	  <Route path="/songs"><Song /></Route> -->

	  <Route path="/blog/e-commerce-and-ai" component="{EcomAi}" />
	  <Route path="/blog/basic-elements-of-ai" component="{BasicAi}" />
	</div>
</Router>
