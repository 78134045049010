<script>
  import { Link } from "svelte-routing";
</script>

<h2>Blog</h2>
<!-- ECOM -->
<div class="toggle-item">
  <div class="project">
    <span class="project-head">
      26 September 2021 — <span class="new">NEW!</span>
    </span>
    <span class="project-files"
      >[<Link to="/blog/e-commerce-and-ai">blog post</Link>]</span
    >
    <span class="project-title"><b>E-commerce and AI</b></span>
    <p class="project-author">Mannat Tuli</p>
    <p class="project-text">
      E-commerce industry has an incredible & impressive growth over the years.
      After COVID era the importance of online shopping has increased manifolds.
      AI has a major role in transforming the way one does shopping from simple
      T-shirt to Big electronic appliances. In this blog I want to highlight Key
      benefits of AI to the organizations & customers.
    </p>
  </div>
</div>
<!-- Basic -->
<div class="toggle-item">
  <div class="project">
    <span class="project-head"> 20 July 2021 </span>
    <span class="project-files"
      >[<Link to="/blog/basic-elements-of-ai">blog post</Link>]</span
    >
    <span class="project-title"><b>Basic Elements of AI</b></span>
    <p class="project-author">Mannat Tuli</p>
    <p class="project-text ai-text">
      Artificial Intelligence is basically a software that is made to imitate
      human behavior. Key elements of AI are Machine learning, computer vision,
      Natural learning processing, Conversational AI and Anamoly detection.
    </p>
  </div>
</div>

<style>
  .toggle-item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px;
    background-color: #ffffff;
    border-radius: 2px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 4px -3px rgb(0 0 0 / 10%);
  }
  .toggle-item p {
    margin-bottom: 3px;
    margin-top: 3px;
  }
  .project-head {
    position: relative;
    text-align: left;
  }
  .project-title {
    hyphens: none;
    text-align: center;
    margin-top: 0.5rem;
    display: block;
    font-weight: 400;
    margin-bottom: 0px;
    clear: both;
  }
  .project-files {
    float: right;
    text-align: right;
    margin-right: 2px;
  }
  .project-text {
    margin-top: 0px;
  }
  .project-author {
    text-align: center;
  }
  .new {
    font-weight: bold;
    background-color: #4d90fe;
    color: white;
    padding: 1px 5px;
    border-radius: 3px;
  }
</style>
