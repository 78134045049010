<script>
  $: unHidden = false;

  let unHide = function () {
    if (unHidden) {
      unHidden = false;
    } else {
      unHidden = true;
    }
  };
</script>

<h2>
  Projects
  <input type="checkbox" id="switch" class="checkbox" on:click={unHide} />
  <label for="switch" class="toggle dot faw" style="cursor: pointer;" />
  <span class="fa-text">Show All</span>
</h2>
{#if unHidden}
  <!-- Themessages -->
  <div class="toggle-item">
    <div class="project">
      <span class="project-head"> October 2021 </span>
      <span class="project-files"
        >[<a href="https://themessages.herokuapp.com/">website</a>]</span
      >
      <span class="project-title"><b>Themessages</b></span>
      <p class="project-author">Mannat Tuli</p>
      <p class="project-text">
        Themessages is an online directory that connects people through a
        chatboard. It's kind of like my own social media!
      </p>
    </div>
  </div>
  <!-- Themessages-lurker -->
  <div class="toggle-item">
    <div class="project">
      <span class="project-head"> 2022 </span>
      <span class="project-files"
        >[<a href="https://github.com/mannattuli/themessages-lurker">code</a
        >]</span
      >
      <span class="project-title"><b>Themessages-lurker</b></span>
      <p class="project-author">Mannat Tuli</p>
      <p class="project-text">
        Webscraper to browse themessages on the terminal. Used lxml instead of
        bs4 and json because I was learning lxml.
      </p>
    </div>
  </div>
  <!-- chess-games-api -->
  <div class="toggle-item">
    <div class="project">
      <span class="project-head"> 2022 </span>
      <span class="project-files"
        >[<a href="https://github.com/mannattuli/chess-games-api">code</a>][<a
          href="https://github.com/mannattuli/chess-games">website</a
        >]</span
      >
      <span class="project-title"><b>chess-games-api </b></span>
      <p class="project-author">Mannat Tuli</p>
      <p class="project-text">
        Rest API for random chess games. The API returns the elo of both the
        player, the game url and the pgn of the game.
      </p>
    </div>
  </div>
  <!-- lazyurl -->
  <div class="toggle-item">
    <div class="project">
      <span class="project-head"> 2022 </span>
      <span class="project-files"
        >[<a href="https://github.com/mannattuli/lazy-url">code</a>][<a
          href="https://lazyurl.herokuapp.com/">website</a
        >]</span
      >
      <span class="project-title"><b>Lazy URL </b></span>
      <p class="project-author">Mannat Tuli</p>
      <p class="project-text">
        A Url shortener. Enter the URL to redirect to and the slug of your
        choice! The format for the shortened URL is
        https://lazyurl.herokuapp.com/x/:slug.
      </p>
    </div>
  </div>
  <!-- CARE -->
  <div class="toggle-item">
    <div class="project">
      <span class="project-head"> Azure Developer League 2021 </span>
      <span class="project-files"
        >[<a href="http://healthybot.azurewebsites.net/">website</a>]</span
      >
      <span class="project-files"
        >[<a
          href="https://docs.google.com/presentation/d/1qj7WdGZTlzz6c_EBlQJUDJU5uRN11iWRCnZp_nz4OtI/edit?usp=sharing"
          >slides</a
        >]</span
      >
      <span class="project-files"
        >[<a href="https://github.com/mannattuli/C.A.R.E">code</a>]</span
      >
      <span class="project-title"><b>C.A.R.E</b></span>
      <p class="project-author">
        Mannat Tuli, <a href="https://kushkaushik14.github.io">Kush Kaushik</a>
      </p>
      <p class="project-text">
        This webapp made on Azure portals is an app developed for patients by my
        team. We added a chatbot which responds to queries and even asks
        questions about symptoms user may be having. All the information that is
        inputted is shown in the form of graphs and the chatbot gives do's and
        don'ts after analsying the data.
      </p>
    </div>
  </div>
{/if}
<!-- Filmania -->
<div class="toggle-item">
  <div class="project">
    <span class="project-head"> December 2021 </span>
    <span class="project-files"
      >[<a href="https://filmzmania.herokuapp.com/">website</a>]</span
    >
    <span class="project-title"><b>Filmania</b></span>
    <p class="project-author">Mannat Tuli</p>
    <p class="project-text">
      Filmania is a social Network based on sharing of ones opinion about a
      movie they watched. You can check your friend's reviews and see what they
      are watching!
    </p>
  </div>
</div>
<!-- WikiSearch -->
<div class="toggle-item">
  <div class="project">
    <span class="project-head"> December 2021 </span>
    <span class="project-files"
      >[<a href="https://github.com/mannattuli/SearchWiki">code</a>]</span
    >
    <span class="project-title"><b>SearchWiki</b></span>
    <p class="project-author">Mannat Tuli</p>
    <p class="project-text">
      A VSCode extension to help you search words right in your editor! Just
      select a word and then press <kbd>Ctrl + Shift + P</kbd>, and then run the
      command <code>searchwiki</code> to get the titles and links of wikipedia pages
      related to the word right in your editor.
    </p>
  </div>
</div>
{#if unHidden}
  <!-- Movieboxd -->
  <div class="toggle-item">
    <div class="project">
      <span class="project-head"> 2021 </span>
      <span class="project-files"
        >[<a href="https://github.com/mannattuli/movieboxd">code</a>]</span
      >
      <span class="project-title"><b>movieboxd </b></span>
      <p class="project-author">Mannat Tuli</p>
      <p class="project-text">
        Keep track of which movie you have watched and whether you liked it or
        not!
      </p>
    </div>
  </div>
  <!-- AXIS -->
  <div class="toggle-item">
    <div class="project">
      <span class="project-head"> Intel AI Bootcamp 2021 </span>
      <span class="project-files"
        >[<a
          href="https://docs.google.com/presentation/d/1V-AxQtYNoXitt_mNorr8jsyZGthuELDAEwuooSgzMlM/edit#slide=id.p"
          >slides</a
        >]</span
      >
      <span class="project-files"
        >[<a href="https://github.com/mannattuli/AXIS">code</a>]</span
      >
      <span class="project-title"><b>AXIS</b></span>
      <p class="project-author">Mannat Tuli</p>
      <p class="project-text">
        An Ai chatbot for the students to easily make the decision of choosing a
        stream or finding a career they might want to pursue!Students nowadays
        do not have sufficient time to even think about their career options,
        this is why a user-friendly chatbot which saves time to determine their
        career choices can be helpful.
      </p>
    </div>
  </div>
  <!-- Chess-Puzzles -->
  <div class="toggle-item">
    <div class="project">
      <span class="project-head"> 2021 </span>
      <span class="project-files"
        >[<a href="https://chess-puzzles.netlify.app/">website</a>]</span
      >
      <span class="project-files"
        >[<a href="https://github.com/mannattuli/chess-puzzle">code</a>]</span
      >
      <span class="project-title"><b>Chess-Puzzles</b></span>
      <p class="project-author">Mannat Tuli</p>
      <p class="project-text">
        Game made on HTML and JavaScript for practicing chess with the help of
        puzzles. You are given a position of chess and have to find the best
        move. Added a solution button which slowly shows all the best moves in
        sequence.
      </p>
    </div>
  </div>
  <!-- StoryBomb -->
  <div class="toggle-item">
    <div class="project">
      <span class="project-head"> 2021 </span>
      <span class="project-files"
        >[<a href="https://storybomb.netlify.app/">website</a>]</span
      >
      <span class="project-files"
        >[<a href="https://github.com/mannattuli/StoryBomb">code</a>]</span
      >
      <span class="project-title"><b>StoryBomb</b></span>
      <p class="project-author">Mannat Tuli</p>
      <p class="project-text">
        You will be offered a choice after every couple of paragraphs, you have
        to choose one and make your story! There are a lot of possible outcomes
        each not related to other so you can play this game a lot of times! Very
        easy , fun and interesting game to play, consisting of different genres
        like, sci fi, drama, and adventure.
      </p>
    </div>
  </div>
{/if}

<style>
  .toggle-item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px;
    background-color: #ffffff;
    border-radius: 2px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 4px -3px rgb(0 0 0 / 10%);
  }
  .toggle-item p {
    margin-bottom: 3px;
    margin-top: 3px;
  }
  .project-head {
    position: relative;
    text-align: left;
  }
  .project-title {
    hyphens: none;
    text-align: center;
    margin-top: 0.5rem;
    display: block;
    font-weight: 400;
    margin-bottom: 0px;
    clear: both;
  }
  .project-files {
    float: right;
    text-align: right;
    margin-right: 2px;
  }
  .project-text {
    margin-top: 0px;
  }
  .project-author {
    text-align: center;
  }
  a {
    text-decoration: none;
    color: #0645ad;
  }
  a:hover {
    text-decoration: underline;
    color: #0645ad;
  }
  .dot {
    float: right;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border: 1px solid #000;
    border-radius: 50%;
  }
  .faw {
    float: right;
    cursor: pointer;
    margin-top: 2px;
  }
  .fa-text {
    font-size: 23px;
    margin-right: 15px;
    float: right;
    padding-top: 5px;
    position: relative;
    font-family: "Latin Modern Roman", "Latin Modern", Cardo, Garamond, serif;
    text-align: justify;
  }
  code,
  kbd {
    font-family: Consolas, "courier new";
    color: crimson;
    background-color: #f1f1f1;
    padding: 2px;
    font-size: 105%;
  }
  /*Toggle Animation*/
  /* toggle in label designing */
  .toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 23px;
    margin-top: 4px;
    background-color: rgb(105, 105, 105);
    border-radius: 30px;
    border: 2px solid transparent;
  }
  /* After slide changes */
  .toggle:after {
    content: "";
    position: absolute;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    top: 1px;
    left: 1px;
    transition: all 0.3s;
  }
  /* Checkbox checked effect */
  .checkbox:checked + .toggle::after {
    left: 28px;
  }
  /* Checkbox checked toggle label bg color */
  .checkbox:checked + .toggle {
    background-color: #4d90fe;
  }
  /* Checkbox vanished */
  .checkbox {
    display: none;
  }
</style>
