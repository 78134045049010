<script>
	import { Link } from "svelte-routing"
</script>

<script context="module">
	let posts = [
	{
		slug: "e-commerce-and-ai",
		title: "E-commerce and AI"
	},
	{
		slug: "basic-elements-of-ai",
		title: "Basic Elements of AI"
	}]
</script>

<style>
ul {
	padding: 0;
}
li {
    list-style: none;
}
</style>

<svelte:head>
	<title>Mannat Tuli | Blog</title>
</svelte:head>
<ul>
	{#each posts as post}
	<li>
	    <Link style="font-size: 1.3rem;
		line-height: 3rem;" to="blog/{post.slug}">		
			{post.title}
		</Link>
	</li>
	{/each}
</ul>
