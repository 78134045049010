<script>
    import { Link } from "svelte-routing"

    import { onMount } from "svelte";

    onMount(() => window.scrollTo(0,0));
</script>

<svelte:head>
  <title>Basic Elements of AI</title>
</svelte:head>

<style>
article h3 :global(a):hover::before {
    content: "# ";
    margin-left: -0.87em;
    color: rgb(141, 141, 141);
}
article h3 :global(a){
    color: #000;
    text-decoration: none;
}
article h3 :global(a):hover{
    text-decoration: none;
}
</style>

<header>
  <p>
    <Link to="/">MANNAT TULI</Link> / <Link to="/blog">BLOG</Link> / 20 JULY 2021
  </p>
  <h1>Basic Elements of AI</h1>
</header>
<article>
    <p>
      Artificial Intelligence is basically a software that is made to imitate human 
      behavior.Key elements of AI are
      <i><strong>Machine learning, computer vision, 
      Natural learning processing, 
      Conversational AI and Anamoly detection.</strong></i>
    </p>
    
    <div>
        <h3 id="index"><a href="#index">Table Of Contents</a></h3>
        <ol>
            <li><a href="#machine-learning">Machine Learning</a></li>
            <li><a href="#computer-vision">Computer Vision</a></li>
            <li><a href="#nlp">Natural Language Processing</a></li>
            <li><a href="#conversational-ai">Conversational AI</a></li>
            <li><a href="#anamoly">Anamoly Detection</a></li>
        </ol>
    </div>
    
      <h3 id="machine-learning"><a href="#machine-learning">Machine Learning:</a></h3>
      <p>
      Machine learning is said to be the base of most AIs.
      Machine learning is the ability of an AI to find patterns, relations in the data
      and make predictions accordingly. 
      
      For example:
      The famous item Pokedex from Pokemon Go works on the basis of Machine learning.
      It has been given the images and names of various pokemons and can now identify 
      any of them just by looking at them.
      
      To start with data scientists feed the AI with data and label them with names of 
      the pokemons. Now this labelled data is processed by the AI, which finds relations
      between them. Now when any pokemon is shown to the AI it can correctly label it
      without any help.
      </p>
      
      <h3 id="computer-vision"><a href="#computer-vision">Computer Vision:</a></h3><p>
      The ability of an AI to visually process is known as computer vision. 
      Computer vision is trained with data and then can identify the contents of images.
      It can detect faces (face detection), detect objects (object detection), 
      classify images (Image classification), read the content of an image (OCR)
      and can classify individual pixels of an image to where they belong (semantic
      segmentation).
      </p>
     
      <h3 id="nlp"><a href="#nlp">Natural Learning Processing:</a></h3> <p>
      NLP is the are of AI which understands spoken language and deals with it.
      NLP can be used to analyse messages in emails, texts etc. It can even synthesize 
      speech. Translators, and text analysis work usually work on NLP. Language
      Understanding Intelligent Service is a system designed by Microsoft that is used 
      to train a model on the basis of spoken or written commands.
      </p>
      
      <h3 id="conversational-ai"><a href="#conversational-ai">Conversational AI:</a></h3><p>
      The AI used to participate in conversations with humans is Conversational AI. 
      Mostley chatbots are used to converse with users, they are placed in various 
      websites and help in providing user an overall good experience. Many bots
      are used in customer service or support, to help in reservations and even in health
      care industry.
      </p>
      
      <h3 id="anamoly"><a href="#anamoly">Anamoly Detection:</a></h3><p>
      The detection of abnormal errors in a data is what anamoly detection is used for.
      For example: 
      When you make an unusual purchase with your credit card sometimes the transaction
      does not go through, this is because your purchase may not suit you usual purchase
      pattern and may come out as a fraud. 
      Anamoly detection can be used to analyse data and then find any abnormal instances.
      </p>
      <p>
          <strong>Source: </strong><a href="https://docs.microsoft.com/en-us/learn/modules/get-started-ai-fundamentals/1-introduction?ns-enrollment-type=LearningPath&ns-enrollment-id=learn.wwl.get-started-with-artificial-intelligence-on-azure">
            Microsoft docs AI-900</a>
      </p>
</article>      