<script>
	import Projects from "../components/Projects.svelte";
	import BlogMain from "../components/BlogMain.svelte";
	import Contact from "../components/Contact.svelte"	
	import Media from "../components/Media.svelte"

	let az900 = "https://www.linkedin.com/in/mannattuli/detail/overlay-view/urn:li:fsd_profileTreasuryMedia:(ACoAADXJy58Bt6c5Y4NmHikLISJC3CweRJOsqIU,1635478309807)/"
</script>

<style>
a {
  text-decoration: none;
  color: #0645ad;
}
a:hover {
  text-decoration: underline;
  color: #0645ad;
}
</style>

<svelte:head>
	<title>Mannat Tuli</title>
</svelte:head>

<h1>Mannat Tuli</h1>
<p>            
	Hello, I'm Mannat Tuli a student currently at Amity International School. 
	I have always enjoyed <a href="https://hackerrank.com/mannattuli6">coding</a> and now have started to work on projects of 
	my own, which I post on this website. Some of my favourite technologies right 
	now are Svelte, RubyOnRails, Postgre. I have an
	<a href="https://www.linkedin.com/feed/update/urn:li:activity:6797586353921036288/" title="AI-900 Certificate">AI-900 certification</a>
	and love working with AI.
</p>
<p>
	Currently, I have a strong grasp of fundamentals of JavaScript and Python, I have been using them for 2 years and 4 years respectively. I have also worked with SQL, Go and TypeScript and am trying to become fluent in them.
</p>
<p>
	I am familiar with React, Svelte, Flask and RubyOnRails and have made webapps on all of them. I have also worked with Microsoft azure and have an <a href="{az900}" title="AZ-900">AZ-900 certification</a>. Furthermore, using powershell and azure portals my team has made a <a href="http://healthybot.azurewebsites.net/" title="C.A.R.E">webapp</a> and a chatbot.
</p>
<p>
	Other than writing code I enjoy playing chess, listening to <a>music</a>, reading <a href="https://www.goodreads.com/user/show/137677761-mxnnxt" title="GoodReads">books</a> and solving the Rubik's Cube.
</p>
<Projects />
<BlogMain />
<Contact />
<Media />
