<script>
    import { Link } from "svelte-routing"

	
    import { onMount } from "svelte";

    onMount(() => window.scrollTo(0,0));
</script>

<style>
	article h3 :global(a):hover::before {
    content: "# ";
    margin-left: -0.87em;
    color: rgb(141, 141, 141);
	}
	article h3 :global(a){
		color: #000;
		text-decoration: none;
	}
	article h3 :global(a):hover{
		text-decoration: none;
	}
</style>

<svelte:head>
  <title>E-commerce and AI</title>
</svelte:head>
<header>
	<p>
		<Link to="/">MANNAT TULI</Link> / <Link to="/blog">BLOG</Link> / 26 SEPTEMBER 2021
	</p>
	<h1>E-commerce and AI</h1>
</header>
<article id="main">
    <p>
      E-commerce industry has an incredible & impressive growth over the
      years. After COVID era the importance of online shopping has increased
      manifolds. AI has a major role in transforming the way one does shopping
      from simple T-shirt to Big electronic appliances. In this blog I want to
      highlight Key benefits of AI to the organizations & customers.
    </p>

    <div>
        <h3 id="index"><a href="#index">Table Of Contents</a></h3>
        <ol>
            <li><a href="#x-sell-and-up-sell">X-sell & Up-sell</a></li>
            <li><a href="#chatbots-and-virtual-assistants">Chatbots and Virtual Assistants</a></li>
            <li><a href="#enhancing-search">Enhancing Search</a></li>
            <li><a href="#farming-potential-customers">Farming potential customers</a></li>
            <li><a href="#multi-channel-approach">Multi-Channel approach</a></li>
            <li><a href="#inventory-management">Inventory Management</a></li>
            <li><a href="#conclusion">Conclusion</a></li>
        </ol>
    </div>

    <h3 id="x-sell-and-up-sell"><a href="#x-sell-and-up-sell">X-sell & Up-sell</a></h3>
    <p>
      Increasing sales & building a healthier sales funnel is important for a
      company as it directly impact the revenue. X-Sell & Up-sell plays a
      vital role in enhancing the sales pipeline & selling high sales margin
      products & services. AI plays a vital role in increasing the hit rate of
      X-sell & upsell as it helps E-commerce companies to sell the products
      which are pertinent to the purchase customers is making, thanks to all
      the data analytics machine learning algorithm which helps in right
      recommendation of add-on products. Deals, offers, bundling of products
      can be customized based on the geographical location, need & wants,
      gender
    </p>
    <h3 id="chatbots-and-virtual-assistants"><a href="#chatbots-and-virtual-assistants">Chatbots and Virtual Assistants</a></h3>
    <p>
      AI powered chatbots help in improving service to the customers.
      Customers can contact chatbots any time or day & can place order, track
      order or can cancel the orders. AI adds automation to the E-Commerce
      customer satisfaction to the next level & is increasingly valuable to
      the organizations for processes from sales to order delivery. AI powered
      chatbots are able to understand user queries through natural language
      processing (NLP) and can give right answers. This is great addition over
      normal chatbot.
    </p>
    <h3 id="enhancing-search"><a href="#enhancing-search">Enhancing Search</a></h3>
    <p>
      AI is now adding new dimensions in search by NLP narrow& contextualizing
      the Key search & OCR adding dimensions to the pictures. Improve search
      results by automatic tagging, organizingthe visual searches, labelling
      of images, GIF & videos. We can create new models & styles with
      pre-built models tosearch using keyword tags or visual similarity.
    </p>
    <h3 id="farming-potential-customers"><a href="#farming-potential-customers">Farming potential customers</a></h3>
    <p>
      AI helps in advertising & offers to pre-qualified potential buyers
      interested in product or service enhancing the shopping experience.
      Machine learning algorithms can help reading the mind of customers using
      data analytics & proposing sales items based on the pre-defined
      criteria. Simple example is facial recognition of customers & data
      driven analytics to offer discounts which are pre-defined based on
      monthly expenditure.
    </p>
    <h3 id="multi-channel-approach"><a href="#multi-channel-approach">Multi-Channel approach</a></h3>
    <p>
      AI helps in targeting customers through multiple channels of indirect
      sales. Organization can use E-mail, Text& Voice all channels to target
      customers & business development. We can initiate calls, Text messages
      based on certain criteria (Dates, Days, Occasions etc.) & target
      potential customers. We can provide Post sales support with multiple
      channels & AI can help in storing data in meaningful formats & engine to
      process it according to criteria defined. AI enabled pre-defined APIs
      help in integrating Web Apps & other software & use existing Channels.
    </p>
    <h3 id="inventory-management"><a href="#inventory-management">Inventory Management</a></h3>
    <p>
      Inventory management is important to any organization to save OPEX, they
      need to maintain the right level of inventory based on market demand &
      fulfilment. This needs to be based on Market demand, Previous year
      sales, Supply management, Logistics & fulfilment which can be integrated
      with Chatbots, Search engines to give same experience. Right management
      of Inventory helps in saving OPEX by space, manpower & power
      requirements. This helps in decreasing caron footprint & giving back to
      society.
    </p>
    <h3 id="conclusion"><a href="#conclusion">Conclusion</a></h3>
    <p>
      To sum it up, as we see in the blog AI is playing an important role in
      driving E-commerce & its use cases. The benefits of AI are tremendous &
      enabling new use cases will help industry to service customer in a
      better, integrated way. AI helps in increasing sales directly &
      indirectly thus impacting sales funnel & corresponding revenue.
    </p>
</article>