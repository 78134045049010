<script>
  import { Link } from "svelte-routing"
</script>

<style>
@media (min-width: 600px) {
  .media-right {
    float: right;
    margin-right: 300px;
  }
}
ul {
  list-style-type: none;
  padding: 0;
}
</style>
<div class="media-right">
    <h2>Media</h2>
    <ul>
        <li>
          <Link to="/songs"  rel="prefetch">Songs</Link>
        </li>
        <li>
            <Link to="/blog" rel="prefetch">Blog</Link>
        </li>
        <li>
          <a href="https://svelte-port.vercel.app/">Website - svelte</a>
        </li>
        <li>
          <a href="https://mannattuli.github.io/">Website - 2021</a>
        </li>
        <li>
          <a href="https://mannattuli.netlify.app/">Website - 2020</a>
        </li>
    </ul>
</div>
